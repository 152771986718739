import React, { useState, useEffect } from 'react';
import {
    useNotify,
    fetchUtils,
    useRedirect,
    AutocompleteArrayInput,
    TextInput,
    BooleanInput,
    FormDataConsumer,
    DateInput,
    NumberInput,
    Loading,
    Error,
    FileInput,
    FileField,
    ImageInput,
    ImageField,
    TabbedForm,
    FormTab,
    SaveButton,
    Toolbar,
    PasswordInput,
    maxLength
} from 'react-admin';
import LocationInput from '../generic_component/LocationInput';
import quality from './qualityChoices';
import dataProvider from '../auth/dataProvider';
import department from './departmentChoices';
import theoreticalFramework from './theoreticalFrameworkChoices';
import takeConsultations from './takeConsultationsChoices';
import inMemoryJWT from '../auth/inMemoryJWT';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import IconButton from '@material-ui/core/IconButton';
import { handleDownloadFile } from '../generic_component/HandleDownloadFile.js';
import DialogTitle from '@material-ui/core/DialogTitle';
import validate from './validate';
import arrayInput from './choiceAnother';
import moment from 'moment';

function PsychologistEditCustom( props ) {
    const redirect = useRedirect();
    const notify = useNotify();
    const [ps, setPs] = useState();
    const [files, setFiles] = useState();
    const [locationId, setLocationId] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        dataProvider.getOne('psychologist', { id: props.id })
          .then(({ data }) => {
              setPs(data)
              let headers = new Headers({ Accept: 'application/json' });
              const token = inMemoryJWT.getToken();
              if (token) {
                  headers.set('Authorization', `${token}`);
              } else {
                 inMemoryJWT.setRefreshTokenEndpoint(process.env.REACT_APP_API_ENDPOINT+'/refresh');
                 return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
                     if (gotFreshToken) {
                         headers.set('Authorization', `${inMemoryJWT.getToken()}`);
                     };
                     return fetchUtils.fetchJson(process.env.REACT_APP_API_ENDPOINT, headers);
                 });
              }
              fetch(process.env.REACT_APP_API_ENDPOINT + '/psychologist/' + props.id + "/file" , {
                  method: 'get',
                  headers: headers,
              })
              .then(response => response.json())
              .then(data => {
                  if (!data.message) {
                      setFiles(data.files);
                      setLoading(false);
                  } else {
                      setFiles({files:{}});
                      setLoading(false);
                  }
              })
              .catch(error => {
                  setError(error);
                  setLoading(false);
              })
          })
    }, [ props.id ]);

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!ps || !files) return null;

    const handleRemoveItem = ( nameFile, name ) => {
        if ( window.confirm( "Se eliminará el archivo. ¿Desea continuar?" ) ){
            let headers = new Headers({ Accept: 'application/json' });
            const token = inMemoryJWT.getToken();
            if (token) {
                headers.set('Authorization', `${token}`);
            } else {
               inMemoryJWT.setRefreshTokenEndpoint(process.env.REACT_APP_API_ENDPOINT+'/refresh');
               return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
                   if (gotFreshToken) {
                       headers.set('Authorization', `${inMemoryJWT.getToken()}`);
                   };
                   return fetchUtils.fetchJson(process.env.REACT_APP_API_ENDPOINT, headers);
               });
            }
            fetch(process.env.REACT_APP_API_ENDPOINT + '/psychologist/' + props.id + "/file?name=" + nameFile , {
                method: 'delete',
                headers: headers,
            })
            .then(json => {
                let newFile = {...files}
                delete newFile[ name ]
                setFiles(newFile)
            })
            .catch(error => {
                notify(error.message);
            })
        }
    }

    const handleDownload = ( nameFile ) => {
        let headers = new Headers({ Accept: 'application/json' });
        const token = inMemoryJWT.getToken();
        if (token) {
            headers.set('Authorization', `${token}`);
        } else {
           inMemoryJWT.setRefreshTokenEndpoint(process.env.REACT_APP_API_ENDPOINT+'/refresh');
           return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
               if (gotFreshToken) {
                   headers.set('Authorization', `${inMemoryJWT.getToken()}`);
               };
               return fetchUtils.fetchJson(process.env.REACT_APP_API_ENDPOINT, headers);
           });
        }
        fetch(process.env.REACT_APP_API_ENDPOINT + '/psychologist/' + props.id + "/file?name=" + nameFile , {
            method: 'get',
            headers: headers,
            responseType: 'blob',
        })
        .then( response => response.blob() )
        .then( blob => handleDownloadFile( blob, nameFile ) )
        .catch(error => {
            setError(error);
            setLoading(false);
            notify(error.message);
        })
    }


    const handleSubmit = async values => {
        let rE = moment(values.registrationExpiration).toDate();
        rE.setHours(12);
        rE.setMinutes(0);
        rE.setSeconds(0);
        let dB = moment(values.dateBirth).toDate();
        dB.setHours(12);
        dB.setMinutes(0);
        dB.setSeconds(0);

        if ( values.department1 ){
            if (values.department) {
                values = {...values, department:values.department.concat("otro:"+values.department1) }
            }else{
                values = {...values, department:[].concat("otro:"+values.department1) }
            }
        }
        if ( values.qualityOfParticipation1 ){
            if (values.qualityOfParticipation) {
                values = {...values, qualityOfParticipation:values.qualityOfParticipation.concat("otro:"+values.qualityOfParticipation1) }
            }else{
                values = {...values, qualityOfParticipation:[].concat("otro:"+values.qualityOfParticipation1) }
            }
        }
        delete values.department1;
        delete values.qualityOfParticipation1;
        delete values.bool;

        dataProvider.update('psychologist', {
            id: props.id,
            data: {
                qualityOfParticipation:values.qualityOfParticipation,
                department:values.department,
                yearEntry:values.yearEntry,
                nationalRegistrationNumber:values.nationalRegistrationNumber,
                registrationExpiration:rE,
                dateBirth:dB,
                theoreticalFramework:values.theoreticalFramework,
                fee:values.fee,
                pet:values.pet,
                flexibleFees:values.flexibleFees,
                dni:values.dni,
                provintialRegistrationNumber:values.provintialRegistrationNumber,
                professionalSynthesis:values.professionalSynthesis,
                comments:values.comments,
                locationId:locationId,
                takeConsultations:values.takeConsultations,
            },
        })
        .then(response => {
            const formData = new FormData();
            let headers = new Headers({});
            const token = inMemoryJWT.getToken();
            if (token) {
                headers.set('Authorization', `${token}`);
            } else {
               inMemoryJWT.setRefreshTokenEndpoint(process.env.REACT_APP_API_ENDPOINT+'/refresh');
               return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
                   if (gotFreshToken) {
                       headers.set('Authorization', `${inMemoryJWT.getToken()}`);
                   };
                   return fetchUtils.fetchJson(process.env.REACT_APP_API_ENDPOINT, headers);
               });
            }
            if ( values.photo ){
                formData.append("photo", values.photo.rawFile);
            } if ( values.cv ){
                formData.append('cv', values.cv.rawFile);
            } if ( values.enrollment ){
                formData.append('enrollment', values.enrollment.rawFile);
            } if ( values.proofOfRegistration ){
                formData.append('proofOfRegistration', values.proofOfRegistration.rawFile);
            } if ( values.collegeDegree ){
                formData.append('collegeDegree', values.collegeDegree.rawFile);
            }

            return fetch(process.env.REACT_APP_API_ENDPOINT + '/psychologist/' + props.id , {
                method: 'put',
                body: formData,
                headers: headers,
            })
            .then(response => {
                response.json();
                notify('Actualizado correctamente');
                redirect('/psychologist')}
            )
            .catch(error => {
                notify(error.message);
            });
        }).catch(error => {
            notify(error.message);
        });

        if (values.password) {
            dataProvider.update('user', {
                id: ps.userId,
                data: {
                    password:values.password,
                },
            })
            .then(response => {
              notify('Actualizado correctamente');
            })
        }
    };

    const MyToolbar = props => (
        <Toolbar {...props} >
            <SaveButton />
        </Toolbar>
    );

    return (
        <TabbedForm save={handleSubmit} validate={validate} toolbar={<MyToolbar />}>
            <FormTab label="Datos profesionales">
                <NumberInput
                    label="Año de ingreso a la RPF"
                    source="yearEntry"
                    fullWidth
                    initialValue={ps.yearEntry ? ps.yearEntry : null}
                />
                <TextInput
                    label="Número de matrícula nacional"
                    source="nationalRegistrationNumber"
                    fullWidth
                    initialValue={ps.nationalRegistrationNumber ? ps.nationalRegistrationNumber : null}
                />
                <DateInput
                    label="Fecha de vencimiento matrícula nacional"
                    source="registrationExpiration"
                    initialValue={ps.registrationExpiration ? ps.registrationExpiration : null}
                />
                <TextInput
                    label="Número de matrícula provincial"
                    source="provintialRegistrationNumber"
                    fullWidth
                    initialValue={ps.provintialRegistrationNumber ? ps.provintialRegistrationNumber : null}
                />
                <NumberInput
                    label="Honorarios en pesos($) por sesion"
                    source="fee"
                    fullWidth
                    initialValue={ps.fee ? ps.fee : null}
                />
                <NumberInput
                    label="Puede flexibilizar sus honorarios? Si es así, cuánto"
                    source="flexibleFees"
                    fullWidth
                    initialValue={ps.flexibleFees ? ps.flexibleFees : null}
                />
                <TextInput
                    label="Si tiene mascotas en el consultorio especifique cuál"
                    source="pet"
                    fullWidth
                    validate={[maxLength(250)]}
                    initialValue={ps.pet ? ps.pet : null}
                />
                <TextInput
                    label="Síntesis profesional"
                    source="professionalSynthesis"
                    validate={[maxLength(250)]}
                    fullWidth
                    initialValue={ps.professionalSynthesis ? ps.professionalSynthesis : null}
                />
                <TextInput
                    fullWidth={true}
                    validate={[maxLength(250)]}
                    multiline
                    label="Comentarios"
                    source="comments"
                    initialValue={ps.comments ? ps.comments : null}
                />
                <AutocompleteArrayInput
                    source="qualityOfParticipation"
                    label="Calidad en la que participa"
                    choices={ quality }
                    validate={[maxLength(999)]}
                    fullWidth
                    initialValue={ps.qualityOfParticipation ? arrayInput(ps.qualityOfParticipation, quality) : null}
                />
                <BooleanInput
                    label="Agregue otra opción si lo requiere"
                    source="bool"
                    style={{ width:"30%" }}
                    fullWidth
                />
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.bool &&
                         <TextInput
                            label="Especifique calidad en la que participa"
                            validate={[maxLength(250)]}
                            source="qualityOfParticipation1"
                            fullWidth
                        />
                    }
                </FormDataConsumer>

                <AutocompleteArrayInput
                    source="theoreticalFramework"
                    label="Marco teorico de referencia"
                    choices={ theoreticalFramework }
                    validate={[maxLength(250)]}
                    fullWidth
                    initialValue={ps.theoreticalFramework ? ps.theoreticalFramework.split(",") : null}
                />
                <AutocompleteArrayInput
                    source="department"
                    label="Departamento/Área/Equipo en el que participa"
                    choices={department}
                    validate={[maxLength(999)]}
                    fullWidth
                    initialValue={ps.department ? arrayInput(ps.department, department) : null}
                />
                <BooleanInput label="Agregue otra opción si lo requiere" source="bool1" fullWidth/>
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.bool1 &&
                         <TextInput
                            label="Especifique departamento/Área/Equipo en el que participa"
                            source="department1"
                            validate={[maxLength(250)]}
                            fullWidth
                        />
                    }
                </FormDataConsumer>
                <AutocompleteArrayInput
                    source="takeConsultations"
                    label="Que tipo de consultas admite?"
                    choices={takeConsultations}
                    fullWidth
                    initialValue={ps.takeConsultations ? ps.takeConsultations.split(",") : null}
                />

            </FormTab>
            <FormTab label="Archivos">
                { ( !files.hasOwnProperty('collegeDegree') ) ? (
                    <FileInput source="collegeDegree" label="Titulo universitario" accept="application/pdf">
                        <FileField download title="title" source="url" />
                    </FileInput>)
                  :
                    <List dense={true}>
                    <DialogTitle>Titulo Universitario</DialogTitle>
                        <ListItem button>
                          <ListItemText
                              primary={files.collegeDegree.url}
                          />
                          <ListItemSecondaryAction style={{marginRight: "30px"}}>
                            <IconButton edge="start" aria-label="download" color="primary" onClick={ () => handleDownload( files.collegeDegree.url ) } >
                              <SaveIcon fontSize="small" />
                            </IconButton>
                          </ListItemSecondaryAction>
                          <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" color="secondary" onClick={ () => handleRemoveItem( files.collegeDegree.url, 'collegeDegree' ) } >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                }

                { ( !files.hasOwnProperty('enrollment') ) ? (
                    <FileInput source="enrollment" label="Matricula" accept="application/pdf">
                        <FileField title="title" source="url" />
                    </FileInput>)
                  :
                    <List dense={true}>
                    <DialogTitle>Matricula</DialogTitle>
                        <ListItem button>
                          <ListItemText
                            primary={files.enrollment.url}
                          />
                          <ListItemSecondaryAction style={{ marginRight: "30px" }}>
                            <IconButton edge="start" aria-label="download" color="primary" onClick={ () => handleDownload( files.enrollment.url ) } >
                              <SaveIcon fontSize="small" style={{marginLeft: "100px"}}/>
                            </IconButton>
                          </ListItemSecondaryAction>
                          <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" color="secondary" onClick={ () => handleRemoveItem( files.enrollment.url, 'enrollment' ) } >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                }

                { ( !files.hasOwnProperty('proofOfRegistration') ) ? (
                    <FileInput source="proofOfRegistration" label="constancia de inscripción" accept="application/pdf">
                        <FileField title="title" source="url" />
                    </FileInput>)
                  :
                    <List dense={true}>
                    <DialogTitle>Constancia de inscripción</DialogTitle>
                        <ListItem button>
                          <ListItemText
                              primary={files.proofOfRegistration.url}
                          />
                          <ListItemSecondaryAction style={{marginRight: "30px"}}>
                            <IconButton edge="start" aria-label="download" color="primary" onClick={ () => handleDownload( files.proofOfRegistration.url ) } >
                              <SaveIcon fontSize="small" />
                            </IconButton>
                          </ListItemSecondaryAction>
                          <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" color="secondary" onClick={ () => handleRemoveItem( files.proofOfRegistration.url, 'proofOfRegistration' ) } >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                }

                { ( !files.hasOwnProperty('cv') ) ? (
                    <FileInput source="cv" label="Curriculum Vitae" accept="application/pdf">
                        <FileField title="title" source="url" />
                    </FileInput>)
                  :
                    <List dense={true}>
                      <DialogTitle>Curriculum vitae</DialogTitle>
                        <ListItem button>
                          <ListItemText
                              primary={files.cv.url}
                          />
                          <ListItemSecondaryAction style={{marginRight: "30px"}}>
                            <IconButton edge="start" aria-label="download" color="primary" onClick={ () => handleDownload( files.cv.url ) } >
                              <SaveIcon fontSize="small" />
                            </IconButton>
                          </ListItemSecondaryAction>
                          <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" color="secondary" onClick={ () => handleRemoveItem( files.cv.url, 'cv' ) } >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                }

                { ( !files.hasOwnProperty('photo') ) ? (
                    <ImageInput source="photo" label="Foto" accept="image/*" placeholder={<p>Subir foto de perfil</p>}>
                        <ImageField download title="title" source="url"/>
                    </ImageInput>)
                  :
                    <List dense={true}>
                    <DialogTitle>Foto</DialogTitle>
                        <ListItem button>
                          <ListItemText
                              primary={files.photo.url}
                          />
                          <ListItemSecondaryAction style={{marginRight: "30px"}}>
                            <IconButton edge="start" aria-label="download" color="primary" onClick={ () => handleDownload( files.photo.url ) } >
                              <SaveIcon fontSize="small" />
                            </IconButton>
                          </ListItemSecondaryAction>
                          <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" color="secondary" onClick={ () => handleRemoveItem( files.photo.url, 'photo' ) } >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                }
            </FormTab>
            <FormTab label="Datos personales">
                <TextInput
                    label="DNI"
                    source="dni"
                    fullWidth
                    validate={[maxLength(250)]}
                    initialValue={ps.dni ? ps.dni : null}
                />
                <DateInput
                    label="Fecha de nacimiento"
                    source="dateBirth"
                    defaultValue={ps.dateBirth ? ps.dateBirth : null}
                />
                <PasswordInput label="Contraseña" source="password" autoComplete='new-password'/>
                <LocationInput setLocation={setLocationId} value={ps.locationId} source="ps.locationId"/>
            </FormTab>
        </TabbedForm>
    );
}

export default PsychologistEditCustom;
