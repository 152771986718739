import React from 'react';
import {
    List,
    TextField,
    ReferenceField,
    TextInput,
    Filter,
    Datagrid,
    Pagination,
} from 'react-admin';
import PsychologistEditCustom from './PsychologistEditCustom';
import PsychologistShowCustom from './PsychologistShowCustom';

const PsychologistPagination = props => <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />;

export const PsychologistList = props => (
    <List
        bulkActionButtons={false}
        filters={props.options.roles !== "psicologx" ? <PsychologistFilter /> : null}
        exporter={false}
        pagination={<PsychologistPagination/>}
        {...props}
    >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <ReferenceField label="Nombre" source="userId" reference="user" link={false}>
                <TextField source="username" />
            </ReferenceField>
        </Datagrid>
    </List>
);

export const PsychologistEdit = props => (
    [( props.options.roles === "psicologx" ) && <PsychologistEditCustom {...props}></PsychologistEditCustom>,
    (( props.options.roles === "derivadorx" ) || ( props.options.roles === "seguidorx" ))
    && <PsychologistShowCustom {...props}></PsychologistShowCustom>,
]);


const PsychologistFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Buscar" source="username" alwaysOn />
    </Filter>
);
