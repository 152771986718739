import React from 'react';
import { Admin, Resource } from 'react-admin';
import UserIcon from '@material-ui/icons/Group';
import EventIcon from '@material-ui/icons/Event';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import { UserList, UserEdit, UserCreate } from './users/users';
import { SessionList, SessionEdit } from './sessions/sessions';
import { PsychologistList, PsychologistEdit } from './psychologists/psychologists';
import { PatientList, PatientEdit, PatientCreate } from './patients/patients';
import authProvider from './auth/authProvider';
import dataProvider from './auth/dataProvider';
import i18nProvider from './i18n/es';

const App = ( ) => (
    <Admin dataProvider={ dataProvider } authProvider={ authProvider } i18nProvider={ i18nProvider }>
        {roles => [
            roles === 'administradorx' ?
                [<Resource name="user" options={{ label: 'Usuarixs', roles: roles }} list={ UserList } create={ UserCreate } edit={ UserEdit } icon={ UserIcon }/>,
                 <Resource name="role" />]
            : null,

            roles === 'derivadorx' || roles === 'seguidorx' ?
                [<Resource name="session" options={{ label: 'Derivaciones', roles: roles }} list={ SessionList } edit={ SessionEdit } icon={ EventIcon }/>,
                <Resource name="psychologist" options={{ label: 'Psicologxs', roles: roles }} list={ PsychologistList } edit={ PsychologistEdit } icon={ RecordVoiceOverIcon }/>,
                <Resource name="patient" options={{ label: 'Pacientes', roles: roles }} list={ PatientList } create={ PatientCreate } edit={ PatientEdit } icon={ LocalHospitalIcon }/>,
                <Resource name="user"/>,
                <Resource name="location"/>]
            : null,

            roles === 'psicologx' ?
                [<Resource name="psychologist" options={{ label: 'Perfil', roles: roles }} list={ PsychologistList } edit={ PsychologistEdit } icon={ RecordVoiceOverIcon }/>,
                <Resource name="session" options={{ label: 'Turnos', roles: roles }} list={ SessionList } edit={ SessionEdit } icon={ EventIcon }/>,
                <Resource name="patient" options={{ label: 'Pacientes', roles: roles }} list={ PatientList } edit={ PatientEdit } icon={ LocalHospitalIcon }/>,
                <Resource name="user"/>,
                <Resource name="location"/>]
            : null,
        ]}
    </Admin>
);
export default App;
//horas trabajadas 150
