import React, { useState } from 'react';
import {
    SimpleForm,
    useNotify,
    useRedirect,
    TextInput,
    ReferenceInput,
    SelectInput,
    PasswordInput,
} from 'react-admin';
import dataProvider from '../auth/dataProvider';
import Button from '@material-ui/core/Button';

function UserCreateCustom( props ) {
    const notify = useNotify();
    const redirect = useRedirect();
    const [pw, setPw] = useState();

    const handleSubmit = async values => {
        let newPw = null;
        if (values.password) {
            newPw = values.password;
        }else {
            newPw = pw;
        }
        dataProvider.create('user', {
            data: {
              username:values.username,
              rolesId:[values.rolesId],
              password:newPw,
            },
        }).then(response => {
            redirect('/user');
            notify('Creado correctamente');
        }).catch(error => {
            notify(error.message);
        });
    };

    const validate = (values) => {
        const errors = {};
        if (!values.username) {
            errors.username = ['requerido'];
        }
        if (!values.rolesId) {
            errors.rolesId = ['requerido'];
        }
        if (!values.password && !pw) {
            errors.password = ['requerido'];
        }
        return errors
    };

    var generator = require('generate-password');
    const generatePassword = () => {
        var password = generator.generate({
            length: 10,
            numbers: true
        });
        setPw(password);
    }

    return (
          <SimpleForm save={handleSubmit} validate={validate}>
              <TextInput label="Apellido y nombre" source="username" />
              <ReferenceInput label="Rol" source="rolesId" reference="role">
                  <SelectInput source="rolesId"/>
              </ReferenceInput>
              {pw ? <PasswordInput value={pw} onChange={() => setPw("")} label="Contraseña" source="password" InputLabelProps={{ shrink: true }} autoComplete='new-password'/>
                : <PasswordInput label="Contraseña" source="password" InputLabelProps={{ shrink: true }} autoComplete='new-password'/>
              }
              <Button variant="contained" color="default" onClick={generatePassword} >
                  Generar contraseña
              </Button>
          </SimpleForm>

    );
}

export default UserCreateCustom;
