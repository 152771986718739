import React, { useState, useEffect } from 'react';
import {
    Button,
    SaveButton,
    SimpleForm,
    useNotify,
    useRedirect,
    Toolbar,
    DateInput,
    TextInput,
    Error,
    Loading,
} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DeleteIcon from '@material-ui/icons/Delete';
import dataProvider from '../../auth/dataProvider';
import { makeStyles } from '@material-ui/core/styles';
import TimeInput from '../TimeInput';
import moment from 'moment';
import Select from 'react-select';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function SessionToolbar({ onCancel, ...props }) {
    let id = props.info.event._def.publicId;
    return (
        <Toolbar {...props}>
            <SaveButton label="Guardar" submitOnEnter={true}/>
            <DeleteItem
                {...props}
                id={id}
                resource="session"
            />
            <CancelButton onClick={onCancel} />
        </Toolbar>
    );
}

function DeleteItem ( props ) {
    const notify = useNotify();
    const redirect = useRedirect();
    const deleteItem = () => dataProvider
        .delete(props.resource, { id: props.id, data: { isApproved: true } })
        .then(response => {
            redirect('/session');
            notify('Horario eliminado');
            props.resetEditEvent();
        })
        .catch(error => {
            notify(`Error: ${error.message}`, 'warning');
        });
    return (
        <Button style={{color:"#f44336"}} label="Eliminar" onClick={deleteItem} >
            <DeleteIcon />
        </Button>
    );
}

function CancelButton( props ) {
    return (
        <Button label="Salir" {...props}>
            <IconCancel />
        </Button>
    );
}

function SessionEdit( props ) {
    const notify = useNotify();
    const classes = useStyles();
    const [session, setSession] = useState();
    const [patient, setPatient] = useState();
    const [state, setState] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    let sessionId = props.info.event._def.publicId;

    useEffect(() => {
        dataProvider.getOne('session', { id: sessionId })
            .then(({ data }) => {
                setLoading(false);
                let resSession = data;
                if ( resSession.patientId ){
                    dataProvider.getOne('patient', { id: resSession.patientId })
                        .then(({ data }) => {
                            setSession(resSession);
                            setPatient(data);
                            setLoading(false);
                        })
                        .catch(error => {
                            setError(error);
                            setLoading(false);
                        })
                }else{
                    setSession(resSession);
                }
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, [sessionId]);

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!session) return null;

    const handleCloseClick = () => {
        props.resetEditEvent();
    };

    const handleSubmit = async values => {
        let start = moment(values.start, 'YYYY-MM-DDHH:mm');
        let startH = values.startTime.substring(0, 2);
        let startM = values.startTime.substring(3, 5);
        start.set({h: startH, m: startM});

        let end = moment(values.end, 'YYYY-MM-DDHH:mm');
        let endH = values.endTime.substring(0, 2);
        let endM = values.endTime.substring(3, 5);
        end.set({h: endH, m: endM});

        dataProvider.update('session', {
            id: props.info.event.id,
            data: {
              state:state,
              start:start._d,
              end:end._d,
              patientId:patient ? patient.id : null
            },
        }).then(response => {
            props.resetEditEvent();
            notify('Horario de disponibilidad asignado');
        }).catch(error => {
            notify(`Error: ${error.message}`, 'warning');

        });
    };

    /**esta funcion debo agregarla por que es la unica forma de obtener el valor del select
    Al FormControl debo agregarlo por que es la unica forma hacer un select que tenga un valor inicial**/
    const handleChange = (event) => {
        setState(event.value);
    };

    let startDate = moment(session.start).format('YYYY MM DD');
    let endDate = moment(session.end).format('YYYY MM DD');
    let startTime = moment(session.start).format('HH:mm');
    let endTime = moment(session.end).format('HH:mm');

    const validate = ( values ) => {
        const errors = {};
        if ( moment(values.end).unix() < moment(values.start).unix() ) {
            errors.end = ['el inicio debe ser anterior al fin del horario disponible'];
        }
        if ( moment(values.endTime).unix() <= moment(values.startTime).unix() ) {
            if ( moment(values.end).unix() <= moment(values.start).unix() ) {
                errors.endTime = ['el inicio debe ser anterior al fin del horario disponible'];
            }
        }

        return errors
    };

    const option = [
        { value: 'terapia culminada', label: 'terapia culminada' },
        { value: 'aceptado', label: 'aceptado' }
    ];

    const option1 = [
        { value: 'aceptado', label: 'aceptado' }
    ];
    return (
        <>
            <Dialog
                fullWidth
                open={props.resetEditEvent}
                onClose={handleCloseClick}
                aria-label="Editar turno"
            >
                <DialogContent>
                    <SimpleForm
                        resource="session"
                        save={handleSubmit}
                        validate={validate}
                        toolbar={<SessionToolbar {...props} onCancel={handleCloseClick}/>}
                    >
                        { patient &&
                            <TextInput
                                label="Paciente"
                                source="patientId"
                                disabled
                                defaultValue={ patient.name }
                                style={{ marginLeft: '20px' }}
                            />
                          }
                        { patient &&
                        <div style={{ marginLeft: '20px',marginBottom: '10px' }}>
                            <Select
                                onChange={handleChange}
                                defaultValue={{ value:session.state, label:session.state}}
                                styles={{ menu: provided => ({ ...provided, zIndex: 9999 })}}
                                options={(session.state === "asignado") ? option1 : option}
                            />
                        </div>
                        }
                        <DateInput
                            label="Desde"
                            type="date"
                            defaultValue={ startDate }
                            source="start"
                            className={classes.textField}
                            InputLabelProps={{ shrink: true }}
                            style={{ display: 'inline', float: 'left', marginLeft: '20px' }}
                        />

                        <TimeInput
                            label="Desde"
                            type="time"
                            source="startTime"
                            className={classes.textField}
                            defaultValue={ startTime }
                            InputLabelProps={{ shrink: true }}
                        />

                        <DateInput
                            label="Hasta"
                            type="date"
                            defaultValue={ endDate }
                            source="end"
                            className={classes.textField}
                            style={{ display: 'inline', float: 'left', marginLeft: '20px' }}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TimeInput
                            label="Hasta"
                            type="time"
                            source="endTime"
                            className={classes.textField}
                            defaultValue={ endTime }
                            InputLabelProps={{ shrink: true }}
                        />

                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default SessionEdit;
