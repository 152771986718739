const department = [
          { id: 'Atención', name: 'Atención' },
          { id: 'Atención/entrevistas iniciales', name: 'Atención/entrevistas iniciales' },
          { id: 'Atención/re derivaciones', name: 'Atención/re derivaciones' },
          { id: 'Atención/supervisiones', name: 'Atención/supervisiones' },
          { id: 'Atención/consejería', name: 'Atención/consejería' },
          { id: 'Atención/consultoría diversa', name: 'Atención/consultoría diversa' },
          { id: 'Formaciones', name: 'Formaciones' },
          { id: 'RRHH', name: 'RRHH' },
          { id: 'Prensa', name: 'Prensa' },
          { id: 'Articulación regional', name: 'Articulación regional' },
          { id: 'Voluntariado', name: 'Voluntariado' },
      ];

export default department;
