const theoreticalFramework = [
          { id: 'Psicoanálisis', name: 'Psicoanálisis' },
          { id: 'Esquizoanálisis', name: 'Esquizoanálisis' },
          { id: 'Cognitivo Conductual', name: 'Cognitivo Conductual' },
          { id: 'Ecléctico', name: 'Ecléctico' },
          { id: 'Sistémica', name: 'Sistémica' },
          { id: 'Gestalt', name: 'Gestalt' },
          { id: 'Psicoterapia', name: 'Psicoterapia' },
          { id: 'Terapia vincular', name: 'Terapia vincular' },
          { id: 'Bioenergética', name: 'Bioenergética' },
          { id: 'Enfoque integrado', name: 'Enfoque integrado' },
      ];
export default theoreticalFramework;
