import React from 'react';
import {
    List,
    TextInput,
    Datagrid,
    TextField,
    Filter,
    Pagination,
} from 'react-admin';
import UserCreateCustom from './UserCreateCustom';
import UserEditCustom from './UserEditCustom';

const UserPagination = props => <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />;

export const UserList = props => (
    <List {...props} exporter={false} pagination={<UserPagination />} filters={<UserFilter />}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField label="Nombre" source="username" />
        </Datagrid>
    </List>
);

export const UserCreate = props => (
    <UserCreateCustom {...props}/>
);

export const UserEdit = props => (
    <UserEditCustom {...props}/>
);

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Buscar" source="username" alwaysOn />
    </Filter>
);
