import React from 'react';
import {
    SimpleForm,
    useNotify,
    useRedirect,
    TextInput,
    BooleanInput,
    FormDataConsumer,
    SelectInput,
    DateInput,
    maxLength,
} from 'react-admin';
import dataProvider from '../auth/dataProvider';
import therapyClass from './therapyClassChoices';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';

function PatientCreateCustom( props ) {
    const notify = useNotify();
    const redirect = useRedirect();

    const handleSubmit = async values => {
        let dateBirthLinkTherapy1 = null;
        let dateBirthLinkTherapy2 = null;
        let dateBirthAdult = null;
        if ( values.dateBirthLinkTherapy1 ) {
            dateBirthLinkTherapy1 = moment(values.dateBirthLinkTherapy1).toDate();
            dateBirthLinkTherapy1.setHours(12);
            dateBirthLinkTherapy1.setMinutes(0);
            dateBirthLinkTherapy1.setSeconds(0);
        }
        if ( values.dateBirthLinkTherapy2 ) {
            dateBirthLinkTherapy2 = moment(values.dateBirthLinkTherapy2).toDate();
            dateBirthLinkTherapy2.setHours(12);
            dateBirthLinkTherapy2.setMinutes(0);
            dateBirthLinkTherapy2.setSeconds(0);
        }
        if ( values.dateBirthAdult ) {
            dateBirthAdult = moment(values.dateBirthAdult).toDate();
            dateBirthAdult.setHours(12);
            dateBirthAdult.setMinutes(0);
            dateBirthAdult.setSeconds(0);
        }
        dataProvider.create('patient', {
            data: {
              therapyClass:values.therapyClass,

              name:values.name,
              reasonConsultationTherapy:values.reasonConsultationTherapy,
              dniEqualLinkTherapy1:values.dniEqualLinkTherapy1,
              dniLinkTherapy1:values.dniLinkTherapy1,
              dateBirthLinkTherapy1:dateBirthLinkTherapy1,
              addressLinkTherapy1:values.addressLinkTherapy1,
              emailLinkTherapy1:values.emailLinkTherapy1,
              phoneLinkTherapy1:values.phoneLinkTherapy1,
              occupationLinkTherapy1:values.occupationLinkTherapy1,
              liveLinkTherapy1:values.liveLinkTherapy1,
              mutualLinkTherapy1:values.mutualLinkTherapy1,
              birthplaceLinkTherapy1:values.birthplaceLinkTherapy1,

              pastTreatmentsLinkTherapy1:values.pastTreatmentsLinkTherapy1,
              experienceLinkTherapy1:values.experienceLinkTherapy1,
              institutionContactLinkTherapy1:values.institutionContactLinkTherapy1,
              medicationLinkTherapy1:values.medicationLinkTherapy1,
              anotherMedicationLinkTherapy1:values.anotherMedicationLinkTherapy1,
              judicializationLinkTherapy1:values.judicializationLinkTherapy1,
              preferencesTherapistsLinkTherapy1:values.preferencesTherapistsLinkTherapy1,
              accessibilityLinkTherapy1:values.accessibilityLinkTherapy1,
              familyBackgroundLinkTherapy1:values.familyBackgroundLinkTherapy1,
              vocationalAreaLinkTherapy1:values.vocationalAreaLinkTherapy1,
              linkedNetworksLinkTherapy1:values.linkedNetworksLinkTherapy1,
              housingConditionsLinkTherapy1:values.housingConditionsLinkTherapy1,
              economicConditionsLinkTherapy1:values.economicConditionsLinkTherapy1,
              petLinkTherapy1:values.petLinkTherapy1,
              commentsLinkTherapy1:values.commentsLinkTherapy1,

              nameLinkTherapy2:values.nameLinkTherapy2,
              dniEqualLinkTherapy2:values.dniEqualLinkTherapy2,
              dniLinkTherapy2:values.dniLinkTherapy2,
              dateBirthLinkTherapy2:dateBirthLinkTherapy2,
              addressLinkTherapy2:values.addressLinkTherapy2,
              occupationLinkTherapy2:values.occupationLinkTherapy2,
              liveLinkTherapy2:values.liveLinkTherapy2,
              mutualLinkTherapy2:values.mutualLinkTherapy2,
              birthplaceLinkTherapy2:values.birthplaceLinkTherapy2,

              pastTreatmentsLinkTherapy2:values.pastTreatmentsLinkTherapy2,
              experienceLinkTherapy2:values.experienceLinkTherapy2,
              institutionContactLinkTherapy2:values.institutionContactLinkTherapy2,
              medicationLinkTherapy2:values.medicationLinkTherapy2,
              anotherMedicationLinkTherapy2:values.anotherMedicationLinkTherapy2,
              judicializationLinkTherapy2:values.judicializationLinkTherapy2,
              accessibilityLinkTherapy2:values.accessibilityLinkTherapy2,
              familyBackgroundLinkTherapy2:values.familyBackgroundLinkTherapy2,
              vocationalAreaLinkTherapy2:values.vocationalAreaLinkTherapy2,
              linkedNetworksLinkTherapy2:values.linkedNetworksLinkTherapy2,
              housingConditionsLinkTherapy2:values.housingConditionsLinkTherapy2,
              economicConditionsLinkTherapy2:values.economicConditionsLinkTherapy2,
              petLinkTherapy2:values.petLinkTherapy2,
              commentsLinkTherapy2:values.commentsLinkTherapy2,

              attentionZoneTherapy:values.attentionZoneTherapy,
              availabilityTherapy:values.availabilityTherapy,
              therapyTypeTherapy:values.therapyTypeTherapy,
              preferencesTherapy:values.preferencesTherapy,
              economicAvailabilityTherapy:values.economicAvailabilityTherapy,
              feministTherapistTherapy:values.feministTherapistTherapy,
              metRPFTherapy:values.metRPFTherapy,
              observationsTherapy:values.observationsTherapy,
              therapistsRecommendationsTherapy:values.therapistsRecommendationsTherapy,

              nameYoungTutor:values.nameYoungTutor,
              linkYoungTutor:values.linkYoungTutor,
              dniYoungTutor:values.dniYoungTutor,
              emailYoungTutor:values.emailYoungTutor,
              phoneYoungTutor:values.phoneYoungTutor,
              addressYoungTutor:values.addressYoungTutor,
              occupationYoungTutor:values.occupationYoungTutor,

              dniEqualYoung:values.dniEqualYoung,
              dniYoung:values.dniYoung,
              dateBirthYoung:values.dateBirthYoung,
              emailYoung:values.emailYoung,
              phoneYoung:values.phoneYoung,
              addressYoung:values.addressYoung,
              liveYoung:values.liveYoung,
              schoolYoung:values.schoolYoung,
              mutualYoung:values.mutualYoung,
              birthplaceYoung:values.birthplaceYoung,

              reasonforConsultationYoung:values.reasonforConsultationYoung,
              pastTreatmentsYoung:values.pastTreatmentsYoung,
              medicationYoung:values.medicationYoung,
              otherMedicationsYoung:values.otherMedicationsYoung,
              judicializationProcessYoung:values.judicializationProcessYoung,
              termsOfProsecutionYoung:values.termsOfProsecutionYoung,
              accessibilityYoung:values.accessibilityYoung,

              attentionZoneYoung:values.attentionZoneYoung,
              petDifficultyYoung:values.petDifficultyYoung,
              availabilityYoung:values.availabilityYoung,
              therapyTypeYoung:values.therapyTypeYoung,
              preferencesYoung:values.preferencesYoung,
              economicAvailabilityYoung:values.economicAvailabilityYoung,
              feministTherapistYoung:values.feministTherapistYoung,
              metRPFYoung:values.metRPFYoung,
              observationsYoung:values.observationsYoung,
              therapistsRecommendationsYoung:values.therapistsRecommendationsYoung,

              familyGroupYoung:values.familyGroupYoung,
              familyGroupDateYoung:values.familyGroupDateYoung,
              significantFactsYoung:values.significantFactsYoung,
              characterizationYoung:values.characterizationYoung,
              familyBackgroundYoung:values.familyBackgroundYoung,
              otherSignificantFactsYoung:values.otherSignificantFactsYoung,

              pregnancyHistoryYoung:values.pregnancyHistoryYoung,
              dreamYoung:values.dreamYoung,
              peerBondsYoung:values.peerBondsYoung,
              behaviorsYoung:values.behaviorsYoung,
              facilitiesYoung:values.facilitiesYoung,

              schoolTrajectoryYoung:values.schoolTrajectoryYoung,
              schoolChangesYoung:values.schoolChangesYoung,
              yearRepeatYoung:values.yearRepeatYoung,
              schoolAdaptationYoung:values.schoolAdaptationYoung,
              linksColleaguesYoung:values.linksColleaguesYoung,
              attitudeYoung:values.attitudeYoung,
              interventionsYoung:values.interventionsYoung,
              schoolContactYoung:values.schoolContactYoung,

              dniEqualAdult:values.dniEqualAdult,
              dniAdult:values.dniAdult,
              dateBirthAdult:dateBirthAdult,
              addressAdult:values.addressAdult,
              emailAdult:values.emailAdult,
              phoneAdult:values.phoneAdult,
              occupationAdult:values.occupationAdult,
              liveAdult:values.liveAdult,
              mutualAdult:values.mutualAdult,
              birthplaceAdult:values.birthplaceAdult,

              reasonforConsultationAdult:values.reasonforConsultationAdult,
              pastTreatmentsAdult:values.pastTreatmentsAdult,
              experienceAdult:values.experienceAdult,
              institutionContactAdult:values.institutionContactAdult,
              medicationAdult:values.medicationAdult,
              anotherMedicationAdult:values.anotherMedicationAdult,
              judicializationAdult:values.judicializationAdult,
              accessibilityAdult:values.accessibilityAdult,
              familyBackgroundAdult:values.familyBackgroundAdult,
              vocationalAreaAdult:values.vocationalAreaAdult,
              linkedNetworksAdult:values.linkedNetworksAdult,
              housingConditionsAdult:values.housingConditionsAdult,
              economicConditionsAdult:values.economicConditionsAdult,

              attentionZoneAdult:values.attentionZoneAdult,
              petDifficultyAdult:values.petDifficultyAdult,
              availabilityAdult:values.availabilityAdult,
              therapyTypeAdult:values.therapyTypeAdult,
              preferencesAdult:values.preferencesAdult,
              economicAvailabilityAdult:values.economicAvailabilityAdult,
              feministTherapistAdult:values.feministTherapistAdult,
              metRPFAdult:values.metRPFAdult,
              observationsAdult:values.observationsAdult,
              therapistsRecommendationsAdult:values.therapistsRecommendationsAdult,

              agreedFees:values.agreedFees,
              clinicHistory:values.clinicHistory,

        }}).then(response => {
            notify('Creado correctamente');
            redirect('/patient');
        }).catch(error => {
            notify(error.message);
        });
    };

    return (
          <SimpleForm save={handleSubmit}>
              <SelectInput label="Tipo de terapia" source="therapyClass" choices={ therapyClass } />
              <FormDataConsumer>
                  {({ formData, ...rest }) => (( formData.therapyClass === "Terapia vincular" ) && [
                      <TextInput label="Motivo de consulta" source="reasonConsultationTherapy" fullWidth validate={[maxLength(800)]}/>,

                      <Typography variant="h4" gutterBottom>Consultante 1</Typography>,
                      <Typography variant="h5" gutterBottom>Datos personales</Typography>,

                      <TextInput label="Apellido y Nombre" source="name" fullWidth validate={[maxLength(250)]}/>,
                      <BooleanInput label="Coincide con el dni?" source="dniEqualLinkTherapy1" style={{ width:"30%" }} fullWidth/>,
                      <TextInput label="DNI" source="dniLinkTherapy1" fullWidth/>,
                      <DateInput label="Fecha de nacimiento" source="dateBirthLinkTherapy1"/>,
                      <TextInput label="Domicilio" source="addressLinkTherapy1" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="Email" source="emailLinkTherapy1" type="email" fullWidth/>,
                      <TextInput label="Telefono" source="phoneLinkTherapy1" fullWidth/>,
                      <TextInput label="Ocupación" source="occupationLinkTherapy1" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="Vive con" source="liveLinkTherapy1" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="Obra social" source="mutualLinkTherapy1" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="Lugar de origen" source="birthplaceLinkTherapy1" fullWidth validate={[maxLength(250)]}/>,

                      <Typography variant="h5" gutterBottom>Información relevante</Typography>,

                      <TextInput label="Tratamientos psicológicos pasados y/o actuales" source="pastTreatmentsLinkTherapy1" fullWidth validate={[maxLength(800)]}/>,
                      <TextInput label="Cómo fueron estas experiencias" source="experienceLinkTherapy1" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="En caso de que se encuentre en tratamiento psiquiátrico, especificar los datos de contacto de psiquiatra/institución donde se atiende" source="institutionContactLinkTherapy1" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="¿Medicación psiquiátrica? ¿Cuál, dosis, desde cuándo?" source="medicationLinkTherapy1" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="Otras medicaciones y/o tratamientos médicos actuales" source="anotherMedicationLinkTherapy1" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="Proceso de judicialización (tipo, instancia, etc)" source="judicializationLinkTherapy1" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="Preferencias en lxs terapeutas (realizar las observaciones necesarias en cuanto a búsquedas de consultante)" source="preferencesTherapistsLinkTherapy1" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="Diversidad funcional, condiciones físicas, zonas, espacios, accesibilidad" source="accessibilityLinkTherapy1" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="Antecedentes familiares en salud mental" source="familyBackgroundLinkTherapy1" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="Área vocacional y/o ocupacional" source="vocationalAreaLinkTherapy1" fullWidth validate={[maxLength(800)]}/>,
                      <TextInput label="Redes vinculares (área familiar, sexo-afectiva, social, etc.)" source="linkedNetworksLinkTherapy1" fullWidth validate={[maxLength(800)]}/>,
                      <TextInput label="Condiciones habitacionales (realizar las observaciones necesarias)" source="housingConditionsLinkTherapy1" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="Condiciones económicas (realizar las observaciones necesarias)" source="economicConditionsLinkTherapy1" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="Observaciones" source="commentsLinkTherapy1" fullWidth validate={[maxLength(1000)]}/>,
                      <Typography variant="h4" gutterBottom>Consultante 2</Typography>,

                      <Typography variant="h5" gutterBottom>Datos personales</Typography>,

                      <TextInput label="Apellido y Nombre" source="nameLinkTherapy2" fullWidth validate={[maxLength(250)]}/>,
                      <BooleanInput label="Coincide con el dni?" source="dniEqualLinkTherapy2" style={{ width:"30%" }} fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="DNI" source="dniLinkTherapy2" fullWidth validate={[maxLength(250)]}/> ,
                      <DateInput label="Fecha de nacimiento" source="dateBirthLinkTherapy2" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Domicilio" source="addressLinkTherapy2" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Ocupacion" source="occupationLinkTherapy2" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Vive con" source="liveLinkTherapy2" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Obra social" source="mutualLinkTherapy2" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Lugar de origen" source="birthplaceLinkTherapy2" fullWidth validate={[maxLength(250)]}/> ,

                      <Typography variant="h5" gutterBottom>Información relevante</Typography>,

                      <TextInput label="Tratamientos psicológicos pasados y/o actuales" source="pastTreatmentsLinkTherapy2" fullWidth validate={[maxLength(800)]}/> ,
                      <TextInput label="Cómo fueron estas experiencias" source="experienceLinkTherapy2" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="En caso de que se encuentre en tratamiento psiquiátrico, especificar los datos de contacto de psiquiatra/institución donde se atiende" source="institutionContactLinkTherapy2" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="¿Medicación psiquiátrica? ¿Cuál, dosis, desde cuándo?" source="medicationLinkTherapy2" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Otras medicaciones y/o tratamientos médicos actuales" source="anotherMedicationLinkTherapy2" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="Proceso de judicialización (tipo, instancia, etc)" source="judicializationLinkTherapy2" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Diversidad funcional, condiciones físicas, zonas, espacios, accesibilidad" source="accessibilityLinkTherapy2" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Antecedentes familiares en salud mental" source="familyBackgroundLinkTherapy2" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Área vocacional y/o ocupacional" source="vocationalAreaLinkTherapy2" fullWidth validate={[maxLength(800)]}/> ,
                      <TextInput label="Redes vinculares (área familiar, sexo-afectiva, social, etc.)" source="linkedNetworksLinkTherapy2" fullWidth validate={[maxLength(800)]}/> ,
                      <TextInput label="Condiciones habitacionales (realizar las observaciones necesarias)" source="housingConditionsLinkTherapy2" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Condiciones económicas (realizar las observaciones necesarias)" source="economicConditionsLinkTherapy2" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Observaciones" source="commentsLinkTherapy2" fullWidth validate={[maxLength(1000)]}/>,

                      <Typography variant="h5" gutterBottom>Datos para la derivación</Typography>,

                      <TextInput label="Zona de atención/terapia online" source="attentionZoneTherapy" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="La persona/s ( consultante 1 ) tiene/n dificultad para atenderse en un lugar donde hay mascotas (realizar las observaciones necesarias en cuanto a particularidades de lx consultante)" source="petLinkTherapy1" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="La persona/s ( consultante 2 ) tiene/n dificultad para atenderse en un lugar donde hay mascotas (realizar las observaciones necesarias en cuanto a particularidades de lx consultante)" source="petLinkTherapy2" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Disponibilidad horaria para ser atendide (especificar días y horas)" source="availabilityTherapy" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Tipo de terapia (realizar las observaciones necesarias en cuanto a búsquedas de consultantes o sugerencias profesionales)" source="therapyTypeTherapy" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Preferencias en lxs terapeutas (realizar las observaciones necesarias en cuanto a búsquedas de consultante)" source="preferencesTherapy" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Disponibilidad económica para atenderse" source="economicAvailabilityTherapy" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Motivo por el cual busca terapeuta feminista" source="feministTherapistTherapy" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="​Cómo se conoció a la RPF" source="metRPFTherapy" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Observaciones" source="observationsTherapy" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Recomendaciones de terapeutas (especificar nombre/s completo/s como aparece en la cartilla)" source="therapistsRecommendationsTherapy" fullWidth validate={[maxLength(250)]}/> ,
                  ])}
              </FormDataConsumer>

              <FormDataConsumer>
                  {({ formData, ...rest }) => (( formData.therapyClass === "Persona menor de edad" ) && [
                      <Typography variant="h4" gutterBottom>Adulte responsable</Typography>,
                      <Typography variant="h5" gutterBottom>Datos personales</Typography>,

                      <TextInput label="Nombre del responsable" source="nameYoungTutor" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Vinculo con el niñe" source="linkYoungTutor" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="DNI del responsable" source="dniYoungTutor" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Email del responsable" source="emailYoungTutor" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Telefono del responsable" source="phoneYoungTutor" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Domicilio del responsable" source="addressYoungTutor" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Ocupacion del responsable" source="occupationYoungTutor" fullWidth validate={[maxLength(250)]}/>,

                      <Typography variant="h5" gutterBottom>Consultante</Typography>,

                      <TextInput label="Nombre y Apellido" source="name" fullWidth validate={[maxLength(250)]}/>,
                      <BooleanInput label="Coincide con el dni?" source="dniEqualYoung" style={{ width:"30%" }} fullWidth/>,
                      <TextInput label="DNI" source="dniYoung" fullWidth/>,
                      <DateInput label="Fecha de nacimiento" source="dateBirthYoung"/>,
                      <TextInput label="Email" source="emailYoung" type="email" fullWidth/>,
                      <TextInput label="Telefono" source="phoneYoung" fullWidth/>,
                      <TextInput label="Domicilio" source="addressYoung" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="Vive con" source="liveYoung" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="Escuela y año escolar" source="schoolYoung" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="Obra social" source="mutualYoung" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="Lugar de origen" source="birthplaceYoung" fullWidth validate={[maxLength(250)]}/>,

                      <Typography variant="h5" gutterBottom>Información relevante</Typography>,

                      <TextInput label="Motivo de consulta" source="reasonforConsultationYoung" fullWidth validate={[maxLength(800)]}/> ,
                      <TextInput label="Tratamientos psicológicos, psicopedagógicos, fonoaudiológicos o psiquiátricos pasados y/o actuales. En caso de que se encuentre en tratamiento actualmente, indique contacto de psiquiatra/institución donde se atiende" source="pastTreatmentsYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="¿Medicación psiquiátrica? ¿Cuál, dosis, desde cuándo?" source="medicationYoung" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="Otras medicaciones y/o tratamientos médicos actuales" source="otherMedicationsYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Proceso de judicialización (tipo, instancia, etc)" source="judicializationProcessYoung" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="En el caso de xadres separades: preguntar específicamente si está judicializado el régimen de comunicación y la cuota alimentaria" source="termsOfProsecutionYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Diversidad funcional, condiciones físicas, zonas, espacios, accesibilidad" source="accessibilityYoung" fullWidth validate={[maxLength(250)]}/> ,

                      <Typography variant="h5" gutterBottom>Datos para la derivación</Typography>,

                      <TextInput label="Zona de atención/terapia online" source="attentionZoneYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="La persona tiene dificultad para atenderse en un lugar donde hay mascotas (realizar las observaciones necesarias en cuanto a particularidades de lx consultante)" source="petDifficultyYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Disponibilidad horaria para ser atendide (especificar días y horas)" source="availabilityYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Tipo de terapia (realizar las observaciones necesarias en cuanto a búsquedas de consultantes o sugerencias profesionales)" source="therapyTypeYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Preferencias en lxs terapeutas (realizar las observaciones necesarias en cuanto a búsquedas de consultante)" source="preferencesYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Disponibilidad económica para atenderse" source="economicAvailabilityYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Motivo por el cual busca terapeuta feminista" source="feministTherapistYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="​Cómo se conoció a la RPF" source="metRPFYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Observaciones" source="observationsYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Recomendaciones de terapeutas (especificar nombre/s completo/s como aparece en la cartilla)" source="therapistsRecommendationsYoung" fullWidth validate={[maxLength(250)]}/> ,

                      <Typography variant="h4" gutterBottom>Información adicional</Typography>,
                      <Typography variant="h5" gutterBottom>Grupo Familiar</Typography>,

                      <TextInput label="Conformación del grupo familiar" source="familyGroupYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Grupo Familiar Conviviente a la fecha" source="familyGroupDateYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Cambios/Pérdidas/Mudanzas o hechos significativos en la vida familiar" source="significantFactsYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Caracterización de los vínculos familiares referidos a la niñe (relación de les xadres con le hije y con el medio familiar inmediato)" source="characterizationYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Antecedentes familiares en salud mental" source="familyBackgroundYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Otros hechos significativos que quieran agregar referido a la estructura o vida familiar" source="otherSignificantFactsYoung" fullWidth validate={[maxLength(250)]}/> ,

                      <Typography variant="h5" gutterBottom>Historia de le niñe</Typography>,

                      <TextInput label="Historia del embarazo y de los primeros años de vida de le niñe" source="pregnancyHistoryYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Cómo es el sueño de le niñe: ¿con quién duerme le niñe?, ¿cómo es su sueño?, ¿presenta dificultades en conciliarlo?" source="dreamYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Vínculos con pares:  ¿tiene amigues? ¿cómo se vincula con elles?" source="peerBondsYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Conductas, emociones o actitudes que les resultan significativas o relevantes de le niñe" source="behaviorsYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Facilidades y/o dificultades en el aprendizaje y en las adquisiciones del desarrollo (aprender a caminar, a hablar, a controlar los esfínteres, en la adquisición de la lectoescritura, etc.)" source="facilitiesYoung" fullWidth validate={[maxLength(250)]}/> ,

                      <Typography variant="h5" gutterBottom>Escuela</Typography>,

                      <TextInput label="Trayectoria escolar" source="schoolTrajectoryYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Cambios de escuela" source="schoolChangesYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Repetición de año" source="yearRepeatYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Adaptación a la nueva escuela o curso nuevo" source="schoolAdaptationYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Vínculos con compañeres y adultes (docentes, directives, etc)" source="linksColleaguesYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Actitud en relación a concurrir a la institución" source="attitudeYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Intervenciones del equipo de orientación escolar" source="interventionsYoung" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="En caso de que la derivación sea por pedido de la institución, pedir el contacto de la misma" source="schoolContactYoung" fullWidth validate={[maxLength(250)]}/> ,

                  ])}
              </FormDataConsumer>

              <FormDataConsumer>
                  {({ formData, ...rest }) => (( formData.therapyClass === "Persona adulta" ) && [
                      <Typography variant="h5" gutterBottom>Datos personales</Typography>,

                      <TextInput label="Nombre y Apellido" source="name" fullWidth validate={[maxLength(250)]}/>,
                      <BooleanInput label="Coincide con el dni?" source="dniEqualAdult" style={{ width:"30%" }} fullWidth/>,
                      <TextInput label="DNI" source="dniAdult" fullWidth/>,
                      <DateInput label="Fecha de nacimiento" source="dateBirthAdult"/>,
                      <TextInput label="Domicilio" source="addressAdult" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="Email" source="emailAdult" type="email" fullWidth/>,
                      <TextInput label="Telefono" source="phoneAdult" fullWidth/>,
                      <TextInput label="Ocupación" source="occupationAdult" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="Vive con" source="liveAdult" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="Obra social" source="mutualAdult" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="Lugar de origen" source="birthplaceAdult" fullWidth validate={[maxLength(250)]}/>,

                      <Typography variant="h5" gutterBottom>Información relevante</Typography>,

                      <TextInput label="Motivo de consulta" source="reasonforConsultationAdult" fullWidth validate={[maxLength(800)]}/> ,
                      <TextInput label="Tratamientos psicológicos pasados y/o actuales" source="pastTreatmentsAdult" fullWidth validate={[maxLength(800)]}/>,
                      <TextInput label="Cómo fueron estas experiencias" source="experienceAdult" fullWidth validate={[maxLength(250)]}/>,
                      <TextInput label="En caso de que se encuentre en tratamiento psiquiátrico, especificar los datos de contacto de psiquiatra/institución donde se atiende" source="institutionContactAdult" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="¿Medicación psiquiátrica? ¿Cuál, dosis, desde cuándo?" source="medicationAdult" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Otras medicaciones y/o tratamientos médicos actuales" source="anotherMedicationAdult" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Proceso de judicialización (tipo, instancia, etc)" source="judicializationAdult" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Diversidad funcional, condiciones físicas, zonas, espacios, accesibilidad" source="accessibilityAdult" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Antecedentes familiares en salud mental" source="familyBackgroundAdult" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Área vocacional y/o ocupacional" source="vocationalAreaAdult" fullWidth validate={[maxLength(800)]}/> ,
                      <TextInput label="Redes vinculares (área familiar, sexo-afectiva, social, etc.)" source="linkedNetworksAdult" fullWidth validate={[maxLength(800)]}/> ,
                      <TextInput label="Condiciones habitacionales (realizar las observaciones necesarias)" source="housingConditionsAdult" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Condiciones económicas (realizar las observaciones necesarias)" source="economicConditionsAdult" fullWidth validate={[maxLength(250)]}/> ,

                      <Typography variant="h5" gutterBottom>Datos para la derivación</Typography>,

                      <TextInput label="Zona de atención/terapia online" source="attentionZoneAdult" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="La persona tiene dificultad para atenderse en un lugar donde hay mascotas (realizar las observaciones necesarias en cuanto a particularidades de lx consultante)" source="petDifficultyAdult" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Disponibilidad horaria para ser atendide (especificar días y horas)" source="availabilityAdult" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Tipo de terapia (realizar las observaciones necesarias en cuanto a búsquedas de consultantes o sugerencias profesionales)" source="therapyTypeAdult" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Preferencias en lxs terapeutas (realizar las observaciones necesarias en cuanto a búsquedas de consultante)" source="preferencesAdult" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Disponibilidad económica para atenderse" source="economicAvailabilityAdult" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Motivo por el cual busca terapeuta feminista" source="feministTherapistAdult" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="​Cómo se conoció a la RPF" source="metRPFAdult" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Observaciones" source="observationsAdult" fullWidth validate={[maxLength(250)]}/> ,
                      <TextInput label="Recomendaciones de terapeutas (especificar nombre/s completo/s como aparece en la cartilla)" source="therapistsRecommendationsAdult" fullWidth validate={[maxLength(250)]}/> ,

                  ])}
              </FormDataConsumer>

              <TextInput fullWidth={true} disabled={ (props.options.roles === "seguidorx" || props.options.roles === "derivadorx")? true : false } validate={[maxLength(50000)]} multiline label="Honorarios acordados" source="agreedFees"/>
              { props.options.roles === "seguidorx" || props.options.roles === "psicologx" ? <TextInput fullWidth={true} multiline label="Historia clinica" source="clinicHistory"/> : null }

          </SimpleForm>

    );
}

export default PatientCreateCustom;
