import React from 'react';
import Calendar from '../generic_component/Calendar/calendar';
import {
    List,
    TextField,
    Datagrid,
    Pagination,
} from 'react-admin';
import SessionFilter from './SessionFilter';
import SessionEditCustom from './SessionEditCustom';
const SessionPagination = props => <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />;

export const SessionList = props => ([
    props.options.roles === "psicologx" &&
        <Calendar {...props} roles={props.options.roles}></Calendar>,
    ( props.options.roles === 'derivadorx' || props.options.roles === 'seguidorx') &&
        <List exporter={false} bulkActionButtons={false} filters={<SessionFilter />} pagination={<SessionPagination/>}{...props}>
            <Datagrid rowClick="edit">
                <TextField source="id" label="id"/>
                <TextField source="Psychologist.user.username" label="Psicologx"/>
                <TextField source="state" label="Estado" />
            </Datagrid>
        </List>
]);

export const SessionEdit = props => (
    <SessionEditCustom {...props}/>
);
