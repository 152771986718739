import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import dataProvider from '../auth/dataProvider';
import { Loading, Error } from 'react-admin';
import Typography from '@material-ui/core/Typography';

function LocationInput( props ){

    const [selectOptions, setSelectOptions] = useState( );
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const [location, setLocation] = useState(true);

    useEffect(( ) => {
        if ( props.value ) {
            dataProvider.getList( 'location', {
               filter:{ id: [ props.value ] },
               pagination: { page: 1, perPage: 100 },
               sort: { field: 'id', order: 'ASC' },
            })
            .then( values => {
                setLocation({
                  "value":values.data.id,
                  "label" : values.data.name + " - " + values.data.department.name + " - " + values.data.department.province.name
                });
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
        }else{
            setLoading(false);
        }
    }, [props.value]);

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!location) return null;

    const getOptions = ( val ) => {
        if ( val==="" ) {
            setSelectOptions([]);
        }else{
            dataProvider.getList( 'location', {
               filter:{ name: val },
               pagination: { page: 1, perPage: 100 },
               sort: { field: 'id', order: 'ASC' },
            })
            .then( values => {
               const options = values.data.map( d => ({
                  "value" : d.id,
                  "label" : d.name + " - " + d.department.name + " - " + d.department.province.name
               }))
               setSelectOptions( options );
            }).catch( error => {
               console.log( error );
            });
        }
    }

    const handleChange = ( e ) => {
        props.setLocation(e.value);
    }

    return (
        <div>
            <Typography variant="h8" gutterBottom>
                Localidad
            </Typography>
            <Select
                isDisabled={props.disabled}
                defaultValue={location}
                onInputChange={getOptions}
                options={selectOptions}
                onChange={handleChange.bind(this)}
            />
        </div>
    );
}

export default LocationInput;
