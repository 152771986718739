const validate = ( values ) => {
    const errors = {};
    if (!values.yearEntry) {
        errors.yearEntry = ['requerido'];
    }
    if (!values.qualityOfParticipation && !values.qualityOfParticipation1) {
        errors.qualityOfParticipation = ['requerido'];
    }
    if (!values.theoreticalFramework) {
        errors.theoreticalFramework = ['requerido'];
    }
    if (!values.department && !values.department1 ) {
        errors.department = ['requerido'];
    }
    if (!values.professionalSynthesis) {
        errors.professionalSynthesis = ['requerido'];
    }
    if (!values.dateBirth) {
        errors.dateBirth = ['requerido'];
    }
    if (!values.fee) {
        errors.fee = ['requerido'];
    }
    if (!values.dni) {
        errors.dni = ['requerido'];
    }
    return errors
};

export default validate;
