import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import SessionCreate from './SessionCreate';
import SessionEdit from './SessionEdit';
import dataProvider from '../../auth/dataProvider';

class Calendar extends React.Component {
    state = {
        currentEvents: [],
        editEvent:false,
        createEvent:false,
        eventInfo: null,
        values: [],
    }

    handleDateSelect = ( selectInfo ) => {
        this.setState({ createEvent:true, eventInfo:selectInfo });
    }

    resetCreateEvent = ( ) => {
        this.setState({ createEvent:false });
    }

    handleEventClick = ( clickInfo ) => {
        this.setState({ editEvent:true, eventInfo:clickInfo });
    }

    resetEditEvent = ( ) => {
        this.setState({ editEvent:false });
    }

    getCalendarData = ( fetchInfo, successCallback ) => {
        dataProvider.getList('session', {
            pagination: { page: 1, perPage: 1000 },
            sort: { field: 'id', order: 'ASC' },
            filter:{ start: fetchInfo.start, end: fetchInfo.end },
        }).then( values => {
            for (var i = 0; i < values.data.length; i++) {
                if ( values.data[i].state === "no asignado" ){
                    values.data[i].title = "disponible";
                }else{
                    values.data[i].title = values.data[i].state;
                }
            }
            successCallback( values.data );
        }).catch(error => {
            console.log(error);
        });
    }

    render() {
        return (
            <div>
                <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    }}
                    initialView='dayGridMonth'
                    editable={false}
                    height={800}
                    selectable={true}//Allows a user to highlight multiple days or timeslots by clicking and dragging.
                    dayMaxEvents={true}
                    weekends={true}
                    hiddenDays={[0]}
                    navLinks={true}
                    eventColor= 'green'
                    locale='es'
                    buttonText={{
                        today:    'Hoy',
                        month:    'Mes',
                        week:     'Semana',
                        day:      'Día',
                        list:     'Lista',
                    }}
                    select={this.handleDateSelect}
                    eventClick={this.handleEventClick}
                    events={(fetchInfo, successCallback) => this.getCalendarData(fetchInfo, successCallback)}
                />
                { this.state.createEvent &&
                    <SessionCreate resetCreateEvent={this.resetCreateEvent} createEvent={this.state.createEvent} info={this.state.eventInfo} />
                }
                { this.state.editEvent &&
                    <SessionEdit resetEditEvent={this.resetEditEvent} info={this.state.eventInfo}/>
                }
            </div>
        )
    }
}

export default Calendar;
