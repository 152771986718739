import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {TextInput} from "ra-ui-materialui";

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: 200,
  },
});

function TimeInput(props) {
  const {classes, source, label, defaultValue, disabled} = props;
  return (
      <TextInput
          source={source}
          label={label}
          type="time"
          disabled={disabled}
          defaultValue={defaultValue}
          className={classes.textField}
          InputLabelProps={{
            shrink: true,
          }}
      />
  );
}

export default withStyles(styles)(TimeInput);
