const itemInArrayOfObject = ( item, items ) => {
    for ( let i = 0; i < items.length; i++ ) {
        if ( item === items[i].id ){
            return true;
        }
    }
    return false;
}

const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
}

const arrayInput = ( items, choices ) => {
    items = items.split(",");
    for ( let i = 0; i < items.length; i++ ) {
        let item = items[i];
        if ( item.includes("otro") ) {
            item = item.substr( 5, item.length );
            items[i]= item;
            if ( !itemInArrayOfObject( item, choices ) ){
                choices.push({ id: item, name: item });
            }
        }
        if ( !itemInArrayOfObject( item, choices ) ){
            choices.push({ id: item, name: item });
        }
    }
    let unique = items.filter( onlyUnique );
    return unique;
}

export default arrayInput;
