import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import { AutocompleteInput, ReferenceInput } from 'react-admin';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: 200,
  },
});

function SuggestsInput(props) {

  return (
      <ReferenceInput
           source={props.source}
           reference={props.reference}
           label={props.label}
           filterToQuery={searchText => ({ name: searchText })}>
                <AutocompleteInput initialValue={ props.initialValue ? props.initialValue : null } optionText="name" />
      </ReferenceInput>
  );
}

export default withStyles(styles)(SuggestsInput);
