import polyglotI18nProvider from 'ra-i18n-polyglot';

const messages = {
    ra: {
        notification: {
            http_error: 'Error de red. Por favor, vuelva a intentar.',
            item_doesnt_exist: 'Elemento inexistente',
            updated: 'Actualizado correctamente',
            canceled: 'Operacion cancelada',
            created: 'Creado correctamente',
            deleted: 'Contenido eliminado',
            logged_out:'Desconectado',
        },
        action: {
            save: 'Guardar',
            delete: 'Eliminar',
            clear_input_value:'Valor de entrada vacio',
            create: 'Crear',
            export: 'Exportar',
            add_filter: 'Filtrar',
            edit: 'Editar',
            cancel: 'Salir',
            refresh: 'Actualizar',
            back: 'Atras',
            open_menu: 'Abrir menú',
            close_menu: 'Cerrar menú',
            bulk_actions:'Acciones en lote',
            sort:'Ordenar',
            undo: 'Deshacer',
            unselect: 'Deseleccionado',
        },
        navigation:{
            page_rows_per_page: 'Registros por pagina',
            no_results: 'No se encontraron resultados',
            page_range_info:'',
            prev:'Anterior',
            next:'Proximo',
        },
        auth:{
            user_menu: 'Salir',
            logout: 'Salir',
            username: 'Usuarix',
            password:'Contraseña',
            sign_in:'Ingresar',
            sign_in_error:'Usuario o contraseña incorrecta',
            auth_check_error: 'Error de verificación de autenticación',
        },
        resources: {
            users:{
                name:'Usuarix',
            }
        },
        validation:{
            required:'Requerido',
            maxLength: 'Superó el máximo de caracteres',
        },
        page:{
            error:'Error',
            list:'',
            showPaciente:'',
            loading:'Cargando',
            empty:'Pagina sin contenido',
            create:'Pagina de creación',
            invite: '¿Quieres crear uno?',
            not_found: 'Pagina no encontrada',
            show:'',
            edit:'Pagina de edición',
        },
        message:{
            error:'Mensaje de error',
            details:'Detalles',
            loading:'Cargando',
            invalid_form:'Formulario inválido',
            not_found:'Pagina no encontrada',
        },
        input:{
            references:{
                single_missing:'',
            },
            file:{
                upload_single:'Subir un archivo',
            }
        }
    },
};

const i18nProvider = polyglotI18nProvider(locale => messages, 'en' );
export default i18nProvider;
