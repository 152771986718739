import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    Filter,
    Pagination,
} from 'react-admin';
import PatientCreateCustom from './PatientCreateCustom';
import PatientEditCustom from './PatientEditCustom';

const PatientFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Buscar" source="name" alwaysOn />
    </Filter>
);
const PatientPagination = props => <Pagination rowsPerPageOptions={[5, 10, 25]} {...props} />;

export const PatientList = props => (
    <List filters={<PatientFilter />} bulkActionButtons={props.options.roles !== "psicologx" ? false : true} exporter={false} pagination={<PatientPagination />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField label="Nombre" source="name" />
        </Datagrid>
    </List>
);

export const PatientEdit = props => (
    <PatientEditCustom {...props}/>
);

export const PatientCreate = props => (
    <PatientCreateCustom {...props}/>
);
