import React from 'react';
import {
    Button,
    SaveButton,
    SimpleForm,
    useNotify,
    useTranslate,
    Toolbar,
    DateInput,
} from 'react-admin';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import dataProvider from '../../auth/dataProvider';
import { makeStyles } from '@material-ui/core/styles';
import TimeInput from '../TimeInput';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

function ButtonToolbar({ onCancel, ...props }) {
    return (
        <Toolbar {...props}>
            <SaveButton label="Marcar como disponible" submitOnEnter={true}/>
            <CancelButton onClick={onCancel} />
        </Toolbar>
    );
}

function CancelButton( props ) {
    const translate = useTranslate();
    return (
        <Button label={translate('ra.action.cancel')} {...props}>
            <IconCancel />
        </Button>
    );
}

function SessionCreate( props ) {
    const classes = useStyles();
    const notify = useNotify();

    const handleCloseClick = () => {
        props.resetCreateEvent();
    };

    const handleSubmit = async values => {
        let start = moment(values.start, 'YYYY-MM-DDHH:mm');
        let startH = values.startTime ? values.startTime.substring(0, 2) : null;
        let startM = values.startTime ? values.startTime.substring(3, 5) : null;
        start.set({h: startH, m: startM});

        let end = moment(values.end, 'YYYY-MM-DDHH:mm');
        let endH = values.endTime ? values.endTime.substring(0, 2) : null;
        let endM = values.endTime ? values.endTime.substring(3, 5) : null;
        end.set({h: endH, m: endM});
        dataProvider.create('session', {
            data: {
              start:start,
              end:end,
            },
        }).then(({ json }) => {
            props.resetCreateEvent();
            notify('Horario de disponibilidad asignado');
        }).catch(error => {
            notify(error.message);
        });
    };

    const validate = ( values ) => {
        const errors = {};
        if ( moment(values.end).unix() < moment(values.start).unix() ) {
            errors.start = ['plazo incorrecto'];
        }

        if ( moment(values.endTime).unix() <= moment(values.startTime).unix() ) {
            if ( moment(values.end).unix() <= moment(values.start).unix() ) {
              errors.start = ['plazo incorrecto'];
            }
        }

        return errors
    };


    return (
        <>
            <Dialog
                fullWidth
                open={props.createEvent}
                onClose={handleCloseClick}
                aria-label="Crear turno"
            >
                <DialogTitle>Indicar el rango de horas disponibles</DialogTitle>
                <DialogContent>
                    <SimpleForm
                        resource="session"
                        save={handleSubmit}
                        validate={validate}
                        toolbar={<ButtonToolbar onCancel={handleCloseClick}/>}
                    >
                        <DateInput
                            label="Desde"
                            type="date"
                            source="start"
                            defaultValue={ props.info.start!==null ? props.info.start : null }
                            className={classes.textField}
                            InputLabelProps={{ shrink: true }}
                            style={{ display: 'inline', float: 'left', marginLeft: '20px' }}
                        />

                        <TimeInput
                            label="Desde"
                            type="time"
                            source="startTime"
                            className={classes.textField}
                            InputLabelProps={{ shrink: true }}
                        />

                        <DateInput
                            label="Hasta"
                            type="date"
                            source="end"
                            defaultValue={ props.info.end!==null ? props.info.end : null }
                            className={classes.textField}
                            style={{ display: 'inline', float: 'left', marginLeft: '20px' }}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TimeInput
                            label="Hasta"
                            type="time"
                            source="endTime"
                            className={classes.textField}
                            InputLabelProps={{ shrink: true }}
                        />
                    </SimpleForm>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default SessionCreate;
