const quality = [
          { id: 'Psicologue asociade', name: 'Psicologue asociade' },
          { id: 'Coordinadore de departamento', name: 'Coordinadore de departamento' },
          { id: 'Responsable de área/equipo', name: 'Responsable de área/equipo' },
          { id: 'Miembrx de área/equipo', name: 'Miembrx de área/equipo' },
          { id: 'Referente regional', name: 'Referente regional' },
          { id: 'Responsable de mi grupo de supervision', name: 'Responsable de mi grupo de supervision' },
          { id: 'Voluntarie', name: 'Voluntarie' },
      ];

export default quality;
