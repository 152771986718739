import * as React from 'react';
import {
  SelectInput,
  TextInput,
  NumberInput,
  DateInput,
  useListContext }
  from 'react-admin';
import Typography from '@material-ui/core/Typography';
import ContentFilter from "@material-ui/icons/FilterList";
import { Form } from 'react-final-form';
import { Box, Button } from '@material-ui/core';
import theoreticalFramework from '../psychologists/theoreticalFrameworkChoices';
import states from './stateChoicesFilter';
import TimeInput from '../generic_component/TimeInput';
import Divider from '@material-ui/core/Divider';
import SuggestsInput from '../generic_component/SuggestsInput';
import moment from 'moment';

const SessionFilter = props => {
  return props.context === "button" ? (
    <SessionFilterButton {...props} />
  ) : (
    <SessionFilterForm {...props} />
  );
};
export default SessionFilter;

const SessionFilterButton = () => {
    const { showFilter } = useListContext();
    return (
        <Button
            size="small"
            color="primary"
            onClick={() => showFilter("main")}
            startIcon={<ContentFilter />}
        >
            Filtro
        </Button>
    );
};

const SessionFilterForm = ({ open }) => {
    const {
        displayedFilters,
        setFilters,
        hideFilter,
    } = useListContext();

    if (!displayedFilters.main) return null;

    const onSubmit = values => {
        if (Object.keys(values).length > 0) {
            let start = null;
            let end = null;
            if ( values.start && values.startTime ){
                start = moment(values.start, 'YYYY-MM-DDHH:mm');
                let startH = values.startTime.substring(0, 2);
                let startM = values.startTime.substring(3, 5);
                start.set({h: startH, m: startM});
                start = start._d;
                values = { ...values, start }
            }

            if ( values.end && values.endTime ){
                end = moment(values.end, 'YYYY-MM-DDHH:mm');
                let endH = values.endTime.substring(0, 2);
                let endM = values.endTime.substring(3, 5);
                end.set({h: endH, m: endM});
                end = end._d;
                values = { ...values, end }
            }
            setFilters(values);
        } else {
            hideFilter("main");
        }
    };

    const resetFilter = () => {
        setFilters({}, []);
    };

    return (
        <div>
        <Form onSubmit={onSubmit}>
            {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
                <Box display="flex" alignItems="flex-end">
                    <Typography variant="h6" gutterBottom>
                        Honorarios de psicologxs
                    </Typography>
                </Box>
                <Box display="flex" alignItems="flex-end" mb={2}>
                    <Box component="span" mr={2} mb={-4}>
                        <NumberInput label="Honorarios Desde" source="startFee"/>
                    </Box>
                    <Box component="span" mr={2} mb={-4}>
                        <NumberInput label="Honorarios Hasta" source="endFee"/>
                    </Box>
                </Box>
                <Box display="flex" alignItems="flex-end">
                    <Typography variant="h6" gutterBottom>
                        Disponibilidad de horarios
                    </Typography>
                </Box>
                <Box display="flex" alignItems="flex-end" mb={2}>
                    <Box component="span" mr={2} mb={-2}>
                        <DateInput
                            label="Desde"
                            type="date"
                            source="start"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>
                    <Box component="span" mr={2} mb={-2}>
                        <TimeInput
                            label="Desde"
                            type="time"
                            source="startTime"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>
                    <Box component="span" mr={2} mb={-2}>
                        <DateInput
                            label="Hasta"
                            type="date"
                            source="end"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>
                    <Box component="span" mr={2} mb={-2}>
                        <TimeInput
                            label="Hasta"
                            type="time"
                            source="endTime"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>
                </Box>
                <Divider/>
                <Box display="flex" alignItems="flex-end" mb={2} mt={2}>
                    <Box component="span" mr={2} mb={-4}>
                        <SelectInput label="Estado" source="state" optionValue="name" resettable choices={states} />
                    </Box>
                    <Box component="span" mr={2} mb={-4}>
                        <SelectInput label="Marco teorico" source="theoreticalFramework" optionValue="name" resettable choices={theoreticalFramework} />
                    </Box>
                    <Box component="span" mr={2} mb={-4}>
                        <TextInput label="Nombre del psicologx" source="username" />
                    </Box>
                    <Box component="span" mr={2} mb={-4}>
                        <SuggestsInput source="locationId" reference="location" label="Zona" searchText="name"/>
                    </Box>
                </Box>

                  <Box display="flex" alignItems="flex-end" mb={3} mt={3}>
                    <Box component="span" mr={2}>
                        <Button variant="outlined" color="primary" type="submit">
                            Filtrar
                        </Button>
                    </Box>
                    <Box component="span">
                        <Button variant="outlined" onClick={resetFilter}>
                            Cerrar
                        </Button>
                    </Box>
                </Box>
            </form>
            )}
        </Form>
        </div>
    );
};
