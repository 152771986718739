import React, { useState, useEffect } from 'react';
import {
    SimpleForm,
    useNotify,
    useRedirect,
    TextInput,
    ReferenceInput,
    Loading,
    Error,
    SelectInput,
    PasswordInput,
    Toolbar,
    SaveButton,
} from 'react-admin';
import dataProvider from '../auth/dataProvider';
import Button from '@material-ui/core/Button';

function UserEditCustom( props ) {
    const notify = useNotify();
    const redirect = useRedirect();
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [pw, setPw] = useState();

    useEffect(() => {
        dataProvider.getOne('user', { id: props.id })
            .then(({ data }) => {
                setUser(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, [props.id]);

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!user) return null;

    const handleSubmit = async values => {
        let newPw = null;
        if (values.password) {
            newPw = values.password;
        }else {
            newPw = pw;
        }
        dataProvider.update('user', {
            id:props.id,
            data: {
              username:values.username,
              rolesId:Array.isArray(values.rolesId)?values.rolesId:[values.rolesId],
              password:newPw,
            },
        }).then(response => {
            redirect('/user');
            notify('Creado correctamente');
        }).catch(error => {
            notify(error.message);
        });
    };

    const validate = (values) => {
        const errors = {};
        if (!values.username) {
            errors.username = ['requerido'];
        }
        if (!values.rolesId) {
            errors.rolesId = ['requerido'];
        }
        return errors
    };

    var generator = require('generate-password');
    const generatePassword = () => {

        var password = generator.generate({
            length: 10,
            numbers: true
        });
        setPw(password);
    }

    const MyToolbar = props => (
        <Toolbar {...props} >
            <SaveButton />
        </Toolbar>
    );
    return (
          <SimpleForm save={handleSubmit} validate={validate} toolbar={<MyToolbar />}>
              <TextInput
                  label="Apellido y nombre"
                  source="username"
                  defaultValue={user.username}
              />
              <ReferenceInput label="Rol" source="rolesId" reference="role">
                  <SelectInput
                      source="rolesId"
                      defaultValue={user.rolesId}
                  />
              </ReferenceInput>
              {pw ? <PasswordInput size="small" value={pw} onChange={() => setPw("")} label="Contraseña" source="password" InputLabelProps={{ shrink: true }} autoComplete='new-password'/>
                : <PasswordInput size="small" label="Contraseña" source="password" InputLabelProps={{ shrink: true }} autoComplete='new-password'/>
              }
              <Button variant="contained" color="default" onClick={generatePassword} >
                  Generar contraseña
              </Button>

          </SimpleForm>

    );
}

export default UserEditCustom;
