import React, { useState, useEffect } from 'react';
import {
    SimpleForm,
    useNotify,
    useRedirect,
    DateInput,
    Error,
    Loading,
    TextInput
} from 'react-admin';
import dataProvider from '../auth/dataProvider';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import TimeInput from '../generic_component/TimeInput'
import SuggestsInput from '../generic_component/SuggestsInput'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

function SessionEditCustom( props ) {
    const notify = useNotify();
    const classes = useStyles();
    const redirect = useRedirect();
    const [session, setSession] = useState();
    const [patient, setPatient] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        dataProvider.getOne('session', { id: props.id })
            .then(({ data }) => {
                setLoading(false);
                let resSession = data;
                if ( resSession.patientId ){
                    dataProvider.getOne('patient', { id: resSession.patientId })
                        .then(({ data }) => {
                            setPatient(data);
                            setSession(resSession);
                            setLoading(false);
                        })
                        .catch(error => {
                            setError(error);
                            setLoading(false);
                        })
                }else{
                    setSession(resSession);
                }
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, [props.id]);

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!session) return null;

    const handleSubmit = async values => {
        let start = moment(values.start, 'YYYY-MM-DDHH:mm');
        let startH = values.startTime.substring(0, 2);
        let startM = values.startTime.substring(3, 5);
        start.set({h: startH, m: startM});

        let end = moment(values.end, 'YYYY-MM-DDHH:mm');
        let endH = values.endTime.substring(0, 2);
        let endM = values.endTime.substring(3, 5);
        end.set({h: endH, m: endM});
        dataProvider.update('session', {
            id: props.id,
            data: {
              start:start._d,
              end:end._d,
              patientId:values.patientId,
            },
        }).then(response => {
            notify('Horario de disponibilidad asignado');
            redirect('/session');
        }).catch(error => {
            notify(error.message);
        });
    };

    const validate = (values) => {
        const errors = {};
        if (!values.state) {
            errors.state = ['requerido'];
        }
        return errors
    };

    let startDate = moment(session.start).format('YYYY MM DD');
    let endDate = moment(session.end).format('YYYY MM DD');
    let startTime = moment(session.start).format('HH:mm');
    let endTime = moment(session.end).format('HH:mm');
console.log("patient", patient);
    return (
        <>
            <SimpleForm save={handleSubmit} validate={validate}>
                { (!patient) ?
                  <SuggestsInput searchText="name" initialValue={(patient) ? patient.id : undefined} label="Paciente" source="patientId" reference="patient"/>
                  :
                  <TextInput
                      label="Paciente"
                      disabled
                      source="patientId"
                      defaultValue={patient.name}
                  />
                }
                <TextInput
                    label="Estados"
                    source="state"
                    optionValue="name"
                    disabled
                    defaultValue={ session.state }
                />
                <DateInput
                    label="Desde"
                    type="date"
                    defaultValue={ startDate }
                    source="start"
                    disabled={patient ? true : false}
                    className={classes.textField}
                    InputLabelProps={{ shrink: true }}
                    style={{ display: 'inline', float: 'left', marginLeft: '0px' }}
                />

                <TimeInput
                    label="Desde"
                    type="time"
                    source="startTime"
                    className={classes.textField}
                    defaultValue={ startTime }
                    disabled={patient ? true : false}
                    InputLabelProps={{ shrink: true }}
                />

                <DateInput
                    label="Hasta"
                    type="date"
                    defaultValue={ endDate }
                    source="end"
                    disabled={patient ? true : false}
                    className={classes.textField}
                    style={{ display: 'inline', float: 'left', marginLeft: '0px' }}
                    InputLabelProps={{ shrink: true }}
                />
                <TimeInput
                    label="Hasta"
                    type="time"
                    source="endTime"
                    disabled={patient ? true : false}
                    className={classes.textField}
                    defaultValue={ endTime }
                    InputLabelProps={{ shrink: true }}
                />
            </SimpleForm>
        </>
    );
}

export default SessionEditCustom;
