import React, { useState, useEffect } from 'react';
import {
    Loading,
    Error,
    fetchUtils,
    useNotify,
    TabbedForm,
    FormTab,
    NumberInput,
    TextInput,
    DateInput,
} from 'react-admin';
import LocationInput from '../generic_component/LocationInput';
import quality from './qualityChoices';
import dataProvider from '../auth/dataProvider';
import department from './departmentChoices';
import theoreticalFramework from './theoreticalFrameworkChoices';
import arrayInput from './choiceAnother';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import inMemoryJWT from '../auth/inMemoryJWT';
import { handleDownloadFile } from '../generic_component/HandleDownloadFile.js';
import SaveIcon from '@material-ui/icons/Save';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import takeConsultations from './takeConsultationsChoices';

function PsychologistShowCustom( props ) {
    const [ps, setPs] = useState();
    const [locationId, setLocationId] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const notify = useNotify();
    const [files, setFiles] = useState();

    useEffect(() => {
        dataProvider.getOne('psychologist', { id: props.id })
          .then(({ data }) => {
              setPs(data)
              let headers = new Headers({ Accept: 'application/json' });
              const token = inMemoryJWT.getToken();
              if (token) {
                  headers.set('Authorization', `${token}`);
              } else {
                 inMemoryJWT.setRefreshTokenEndpoint(process.env.REACT_APP_API_ENDPOINT+'/refresh');
                 return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
                     if (gotFreshToken) {
                         headers.set('Authorization', `${inMemoryJWT.getToken()}`);
                     };
                     return fetchUtils.fetchJson(process.env.REACT_APP_API_ENDPOINT, headers);
                 });
              }
              fetch(process.env.REACT_APP_API_ENDPOINT + '/psychologist/' + props.id + "/file" , {
                  method: 'get',
                  headers: headers,
              })
              .then(response => response.json())
              .then(data => {
                  if (!data.message) {
                      setFiles(data.files);
                      setLoading(false);
                  } else {
                      setFiles({files:{}});
                      setLoading(false);
                  }
              })
              .catch(error => {
                  setError(error);
                  setLoading(false);
              })
          })
    }, [ props.id ]);

    if (loading) return <Loading />;
    if (error) return <Error />;
    if (!ps || !files) return null;


    const handleDownload = ( nameFile ) => {
        let headers = new Headers({ Accept: 'application/json' });
        const token = inMemoryJWT.getToken();
        if (token) {
            headers.set('Authorization', `${token}`);
        } else {
           inMemoryJWT.setRefreshTokenEndpoint(process.env.REACT_APP_API_ENDPOINT+'/refresh');
           return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
               if (gotFreshToken) {
                   headers.set('Authorization', `${inMemoryJWT.getToken()}`);
               };
               return fetchUtils.fetchJson(process.env.REACT_APP_API_ENDPOINT, headers);
           });
        }
        fetch(process.env.REACT_APP_API_ENDPOINT + '/psychologist/' + props.id + "/file?name=" + nameFile , {
            method: 'get',
            headers: headers,
            responseType: 'blob',
        })
        .then( response => response.blob() )
        .then( blob => handleDownloadFile( blob, nameFile ) )
        .catch(error => {
            setError(error);
            setLoading(false);
            notify(error.message);
        })
    }

    return (
        <TabbedForm toolbar={false}>
            <FormTab label="Datos profesionales">
                <NumberInput
                    label="Año de ingreso a la RPF"
                    source="yearEntry"
                    fullWidth
                    disabled
                    initialValue={ps.yearEntry ? ps.yearEntry : null}
                />
                <TextInput
                    label="Número de matrícula nacional"
                    source="nationalRegistrationNumber"
                    fullWidth
                    disabled
                    initialValue={ps.nationalRegistrationNumber ? ps.nationalRegistrationNumber : null}
                />
                <DateInput
                    label="Fecha de vencimiento matrícula"
                    source="registrationExpiration"
                    initialValue={ps.registrationExpiration ? ps.registrationExpiration : null}
                    disabled
                />
                <TextInput
                    label="Número de matrícula provincial"
                    source="provintialRegistrationNumber"
                    disabled
                    fullWidth
                    initialValue={ps.provintialRegistrationNumber ? ps.provintialRegistrationNumber : null}
                />
                <NumberInput
                    label="Honorarios en pesos($) por sesion"
                    source="fee"
                    disabled
                    fullWidth
                    initialValue={ps.fee ? ps.fee : null}
                />
                <NumberInput
                    label="Puede flexibilizar sus honorarios? Si es así, cuánto"
                    source="flexibleFees"
                    disabled
                    fullWidth
                    initialValue={ps.flexibleFees ? ps.flexibleFees : null}
                />
                <TextInput
                    label="Si tiene mascotas en el consultorio especifique cuál"
                    source="pet"
                    disabled
                    fullWidth
                    initialValue={ps.pet ? ps.pet : null}
                />
                <TextInput
                    label="Síntesis profesional"
                    source="professionalSynthesis"
                    disabled
                    fullWidth
                    initialValue={ps.professionalSynthesis ? ps.professionalSynthesis : null}
                />
                <TextInput
                    fullWidth={true}
                    multiline
                    label="Comentarios"
                    disabled
                    source="comments"
                    initialValue={ps.comments ? ps.comments : null}
                />
                <TextInput
                    source="qualityOfParticipation"
                    label="Calidad en la que participa"
                    choices={ quality }
                    disabled
                    fullWidth
                    initialValue={ps.qualityOfParticipation ? arrayInput(ps.qualityOfParticipation, quality) : null}
                />
                <TextInput
                    source="theoreticalFramework"
                    label="Marco teorico de referencia"
                    choices={ theoreticalFramework }
                    disabled
                    initialValue={ps.theoreticalFramework ? ps.theoreticalFramework.split(",") : null}
                />
                <TextInput
                    source="department"
                    label="Departamento/Área/Equipo en el que participa"
                    choices={department}
                    disabled
                    fullWidth
                    initialValue={ps.department ? arrayInput(ps.department, department) : null}
                />
                <TextInput
                    source="takeConsultations"
                    label="Que tipo de consultas admite?"
                    choices={takeConsultations}
                    disabled
                    fullWidth
                    initialValue={ps.takeConsultations ? ps.theoreticalFramework.split(",") : null}
                />
            </FormTab>
            <FormTab label="Archivos">
                { ( files.hasOwnProperty('collegeDegree') ) &&
                    <List dense={true}>
                    <DialogTitle>Titulo Universitario</DialogTitle>
                        <ListItem button>
                          <ListItemText
                              primary={files.collegeDegree.url}
                          />
                          <ListItemSecondaryAction style={{marginRight: "30px"}}>
                            <IconButton edge="start" aria-label="download" color="primary" onClick={ () => handleDownload( files.collegeDegree.url ) } >
                              <SaveIcon fontSize="small" />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                }

                { ( files.hasOwnProperty('enrollment') ) &&
                    <List dense={true}>
                    <DialogTitle>Matricula</DialogTitle>
                        <ListItem button>
                          <ListItemText
                            primary={files.enrollment.url}
                          />
                          <ListItemSecondaryAction style={{ marginRight: "30px" }}>
                            <IconButton edge="start" aria-label="download" color="primary" onClick={ () => handleDownload( files.enrollment.url ) } >
                              <SaveIcon fontSize="small" style={{marginLeft: "100px"}}/>
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                }

                { ( files.hasOwnProperty('proofOfRegistration') ) &&
                    <List dense={true}>
                    <DialogTitle>Constancia de inscripción</DialogTitle>
                        <ListItem button>
                          <ListItemText
                              primary={files.proofOfRegistration.url}
                          />
                          <ListItemSecondaryAction style={{marginRight: "30px"}}>
                            <IconButton edge="start" aria-label="download" color="primary" onClick={ () => handleDownload( files.proofOfRegistration.url ) } >
                              <SaveIcon fontSize="small" />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                }

                { ( files.hasOwnProperty('cv') ) &&
                    <List dense={true}>
                      <DialogTitle>Curriculum vitae</DialogTitle>
                        <ListItem button>
                          <ListItemText
                              primary={files.cv.url}
                          />
                          <ListItemSecondaryAction style={{marginRight: "30px"}}>
                            <IconButton edge="start" aria-label="download" color="primary" onClick={ () => handleDownload( files.cv.url ) } >
                              <SaveIcon fontSize="small" />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                }

                { ( files.hasOwnProperty('photo') ) &&
                    <List dense={true}>
                    <DialogTitle>Foto</DialogTitle>
                        <ListItem button>
                          <ListItemText
                              primary={files.photo.url}
                          />
                          <ListItemSecondaryAction style={{marginRight: "30px"}}>
                            <IconButton edge="start" aria-label="download" color="primary" onClick={ () => handleDownload( files.photo.url ) } >
                              <SaveIcon fontSize="small" />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                }
            </FormTab>
            <FormTab label="Datos personales">
                <TextInput
                    label="DNI"
                    source="dni"
                    disabled
                    fullWidth
                    initialValue={ps.dni ? ps.dni : null}
                />
                <DateInput
                    label="Fecha de nacimiento"
                    disabled
                    source="dateBirth"
                    defaultValue={ps.dateBirth ? ps.dateBirth : null}
                />
                <LocationInput disabled={true} setLocation={setLocationId} value={ps.locationId} source="ps.locationId"/>
            </FormTab>
        </TabbedForm>
    );
}

export default PsychologistShowCustom;
